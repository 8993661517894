import { useState } from "react";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import {
  HeaderSection,
  CustomNavLinkSmall,
  Span,
  FooterContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (
      <>
        <CustomNavLinkSmall onClick={() => scrollTo("middle")}>
          <Span>{t("Music")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("contact")}>
          <Span>{t("Merch")}</Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <img
        src={`/img/svg/logo.png`}
        alt={"logo.png"}
        width={"101px"}
        height={"64px"}
        style={{ display: "block", margin: "auto" }}
      />
      <div style={{ justifyContent: "center", display: "flex" }}>
        <MenuItem />
      </div>
      <FooterContainer>
        <SocialLink
          href="https://www.youtube.com/channel/UCkFUnlZdaS1mcbILqicBosw"
          src="youtube.png"
        />
        <SocialLink
          href="https://www.tiktok.com/@bii_ms?is_from_webapp=1&sender_device=pc"
          src="tiktok.png"
        />
        <SocialLink
          href="https://www.instagram.com/actually_bii/"
          src="Instagram.png"
        />
      </FooterContainer>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
